import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`2.2. Your Responsibilities as a Buyer:`}</h3>
    <p>{`When You use a Payment Service Provider on HostJane to pay for a Seller's Listing, You represent and warrant to Us and Your Seller that:`}</p>
    <ol>
      <li parentName="ol">{`You are legally authorized or have permission to provide payment details to the Payment Service Provider`}</li>
      <li parentName="ol">{`You are purchasing the Service in good-faith with a legal, valid payment source that belongs either to Yourself or to an employer or agent of a person who owns the payment method who has legally authorized You to use; `}</li>
      <li parentName="ol">{`That the source of funds and Your use of the payment method used to remit payment to HostJane does not violate any local, state or federal law. We require ID verification through all Payment Service Providers and reserve the right to ban offenders on a lifetime basis.`}</li>
      <li parentName="ol">{`All transfer and assignment of intellectual property to the Buyer shall be subject to full payment for the Listing and the delivered Work Preview and Final Work may not be used if payment is cancelled for any reason. `}</li>
      <li parentName="ol">{`That by using the Site You will not attempt to illegally gain unpaid Work Previews or Final Work from Seller without legal payment for the order as defined by Seller's Listing, which results in immediate account suspension and potential lifetime banning.`}</li>
      <li parentName="ol">{`That You have carefully considered the suitability of Your chosen Service and associated License if applicable, and that You have chosen appropriately. HostJane encourages buyers to read and leave reviews and feedback.`}</li>
      <li parentName="ol">{`After You have released funds to the Seller, You must use the Site's dispute system to request cancellation of a completed purchase of a Service within five (5) days of the date of the order completion. Except in exceptional circumstances where there has been a breach of HostJane's policies, disputes on completed orders that are raised after the five (5) day Waiting Period has expired are practically non-refundable.`}</li>
      <li parentName="ol">{`That HostJane may provide relevant, non-payment details to Seller of the Service in order for her or him to facilitate Your transaction, for example for invoicing and customer support services. HostJane keeps Your payment information confidential.`}</li>
      <li parentName="ol">{`HostJane and Seller have the right to enforce against You the terms of the License that You have acquired from the Seller in the delivery of the Work. `}</li>
      <li parentName="ol">{`As part of our General Terms of Service on User Content, HostJane retains the right to use all delivered Works for HostJane marketing and promotion purposes.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`D. Failure to Respond to Abuse Department`}</h2>
    <p>{`A failure to respond to an email (or contact request) from Our abuse department within forty-eight (48) hours, or as otherwise specified in the request, may result in the suspension or termination of Your Services. All abuse issues must be dealt with via trouble ticket/email and will receive a response within forty-eight (48) hours.`}</p>
    <h2>{`E. How to Report Abuse to HostJane`}</h2>
    <p>{`If You feel You have discovered a violation of Our AUP please report it to the HostJane team at the bottom of this page.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`2. HOW DO WE USE COOKIES?`}</h3>
    <p>{`We use cookies and other tracking technologies to make Our Websites easier to use and to better tailor them to Your interests and needs. We use the information We obtain from cookies and other tracking technologies to carry out profiling activities in order to learn more about You and offer You tailored advertising based on Your behavior on Our Websites. We also use these technologies to compile information that allows Us to better understand Our customers and visitors.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`3.2 Earnings; Withdrawals`}</h3>
    <p><strong parentName="p">{`3.2.1`}</strong>{` Any order Seller receives is deemed to be successfully completed (with a timestamp for Completion given) when the following conditions have been met:`}</p>
    <ul>
      <li parentName="ul">{`Buyer has formally released funds to Seller after receiving Work Preview`}</li>
      <li parentName="ul">{`Buyer has left public feedback for Seller`}</li>
      <li parentName="ul">{`Seller has delivered Work Preview to the Buyer`}</li>
      <li parentName="ul">{`No open dispute exists between Buyer and Seller`}</li>
      <li parentName="ul">{`Both Buyer, Seller and Order are in compliance with HostJane's General Terms of Service, Acceptable Use Policy and any other applicable law.`}</li>
    </ul>
    <p><strong parentName="p">{`3.2.2`}</strong>{` HostJane will accredit Seller's account with Earnings of 95% of the fixed-priced amount paid by the Buyer for the Listing. `}</p>
    <p><strong parentName="p">{`3.2.3`}</strong>{` HostJane's standard Marketplace Fee is 5% of the cost of listings and custom order prices set by the Seller, and tips. The Marketplace Fee covers hosting, marketing and all associated platform costs.`}</p>
    <p><strong parentName="p">{`3.2.4`}</strong>{` For the Earnings to be available to the Seller's account, a Waiting Period of 14 business days must elapse from the time stamp of Order Completion.`}</p>
    <p>{`As an example, if the Order is deemed complete at 00:01 on Monday, January 3, 2028, the funds will be available in Seller's account on Friday, January 7, 2028 at 23:59.`}</p>
    <p><strong parentName="p">{`3.2.5`}</strong>{` After the five (5) day Waiting Period has elapsed, Seller may provide their details for one (1) Payment Services Provider method for withdrawal of funds to Seller's receiving account or financial institution (“Payout Method”), through the security-protected Settings area of Your account.`}</p>
    <p><strong parentName="p">{`3.2.6`}</strong>{` Fees associated with use of Payment Services Provider(s) are given in the Settings area of Your account. `}</p>
    <p><strong parentName="p">{`3.2.7`}</strong>{` To request a withdrawal, Seller must have successfully saved a valid Payout Method in their HostJane account settings and initiated an outbound payout through their HostJane Balance page which generates a unique Payout Reference ID.`}</p>
    <p><strong parentName="p">{`3.2.8`}</strong>{` All funds are remitted in U.S. dollars.`}</p>
    <p><strong parentName="p">{`3.2.9`}</strong>{` Minimum payout thresholds vary based on Payment Service Provider.`}</p>
    <p><strong parentName="p">{`3.2.10`}</strong>{` HostJane's customer service team will remit payment to the Seller's account between 3-5 business days of a Payout request being received from the Seller. `}</p>
    <p><strong parentName="p">{`3.2.11`}</strong>{` HostJane cannot be held responsible for delays, extra costs or financial loss that arises from being provided incorrect financial account information by Seller. `}</p>
    <p><strong parentName="p">{`3.2.12`}</strong>{` Insurance; Sellers are responsible for obtaining a general liability insurance policy with coverage amounts that are sufficient to cover all risks associated with the performance of their Services.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import { LegalBlock, LegalContent, LegalTldr } from "../../../../src/components/Legal/index.js";
import OneMainAup from "../../../../content/legal/parts/aup/1/aup-main.mdx";
import OneTldrAup from "../../../../content/legal/parts/aup/1/aup-tldr.mdx";
import TwoMainAup from "../../../../content/legal/parts/aup/2/aup-main.mdx";
import TwoTldrAup from "../../../../content/legal/parts/aup/2/aup-tldr.mdx";
import ThreeMainAup from "../../../../content/legal/parts/aup/3/aup-main.mdx";
import ThreeTldrAup from "../../../../content/legal/parts/aup/3/aup-tldr.mdx";
import FourMainAup from "../../../../content/legal/parts/aup/4/aup-main.mdx";
import FourTldrAup from "../../../../content/legal/parts/aup/4/aup-tldr.mdx";
import FiveMainAup from "../../../../content/legal/parts/aup/5/aup-main.mdx";
import FiveTldrAup from "../../../../content/legal/parts/aup/5/aup-tldr.mdx";
import SixMainAup from "../../../../content/legal/parts/aup/6/aup-main.mdx";
import SixTldrAup from "../../../../content/legal/parts/aup/6/aup-tldr.mdx";
import SevenMainAup from "../../../../content/legal/parts/aup/7/aup-main.mdx";
import SevenTldrAup from "../../../../content/legal/parts/aup/7/aup-tldr.mdx";
import EightMainAup from "../../../../content/legal/parts/aup/8/aup-main.mdx";
import EightTldrAup from "../../../../content/legal/parts/aup/8/aup-tldr.mdx";
import NineMainAup from "../../../../content/legal/parts/aup/9/aup-main.mdx";
import NineTldrAup from "../../../../content/legal/parts/aup/9/aup-tldr.mdx";
import TenMainAup from "../../../../content/legal/parts/aup/10/aup-main.mdx";
import TenTldrAup from "../../../../content/legal/parts/aup/10/aup-tldr.mdx";
import ElevenMainAup from "../../../../content/legal/parts/aup/11/aup-main.mdx";
import ElevenTldrAup from "../../../../content/legal/parts/aup/11/aup-tldr.mdx";
import TwelveMainAup from "../../../../content/legal/parts/aup/12/aup-main.mdx";
import TwelveTldrAup from "../../../../content/legal/parts/aup/12/aup-tldr.mdx";
import ThirteenMainAup from "../../../../content/legal/parts/aup/13/aup-main.mdx";
import ThirteenTldrAup from "../../../../content/legal/parts/aup/13/aup-tldr.mdx";
import FourteenMainAup from "../../../../content/legal/parts/aup/14/aup-main.mdx";
import FourteenTldrAup from "../../../../content/legal/parts/aup/14/aup-tldr.mdx";
import FifteenMainAup from "../../../../content/legal/parts/aup/15/aup-main.mdx";
import FifteenTldrAup from "../../../../content/legal/parts/aup/15/aup-tldr.mdx";
import SixteenMainAup from "../../../../content/legal/parts/aup/16/aup-main.mdx";
import SixteenTldrAup from "../../../../content/legal/parts/aup/16/aup-tldr.mdx";
import * as React from 'react';
export default {
  LegalBlock,
  LegalContent,
  LegalTldr,
  OneMainAup,
  OneTldrAup,
  TwoMainAup,
  TwoTldrAup,
  ThreeMainAup,
  ThreeTldrAup,
  FourMainAup,
  FourTldrAup,
  FiveMainAup,
  FiveTldrAup,
  SixMainAup,
  SixTldrAup,
  SevenMainAup,
  SevenTldrAup,
  EightMainAup,
  EightTldrAup,
  NineMainAup,
  NineTldrAup,
  TenMainAup,
  TenTldrAup,
  ElevenMainAup,
  ElevenTldrAup,
  TwelveMainAup,
  TwelveTldrAup,
  ThirteenMainAup,
  ThirteenTldrAup,
  FourteenMainAup,
  FourteenTldrAup,
  FifteenMainAup,
  FifteenTldrAup,
  SixteenMainAup,
  SixteenTldrAup,
  React
};
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`3. HostJane Content`}</h2>
    <p>{`Except for User Content (as defined below), all content available through the Services,
including designs, text, graphics, images, video, information, software, audio and other
files, and their selection and arrangement, and all software used to provide the Services
(collectively, "HostJane Content"), are the proprietary property of HostJane or
HostJane's licensors. HostJane Content may not be modified, copied, distributed,
framed, reproduced, republished, downloaded, scraped, displayed, posted, transmitted,
sold or exploited for any purpose in any form or by any means, in whole or in part, other
than as expressly permitted in this Agreement. You may not, directly or indirectly,
reverse engineer, decompile, disassemble or otherwise attempt to derive source code
or other trade secrets from any HostJane Content. Any use of HostJane Content,
other than as specifically authorized herein, is prohibited and will automatically
terminate Your rights to use the Services and any HostJane Content. All rights to use
HostJane Content that are not expressly granted in this Agreement are reserved by HostJane
and HostJane's licensors.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`2. Information You Provide`}</h3>
    <p>{`In order to access or use certain portions of the Site or Services, or enjoy the full functionality of the Site or Services, or otherwise in conducting business with Us or seeking to conduct business with Us, You may be prompted to provide certain personal data to Us in the following ways:`}</p>
    <ol>
      <li parentName="ol">{`Persons who subscribe to Our Services will be prompted to provide their name, their address, their e-mail, their phone number, and any other necessary billing information.`}</li>
      <li parentName="ol">{`Persons who complete registration forms to sign up for membership on Our Site will be prompted to provide their e-mail address`}</li>
      <li parentName="ol">{`Site visitors, and prospective and current suppliers and subscribers, may provide personal data when filling in forms (for example, a 'Contact Us' form) on Our Site or at a trade show or anywhere else we conduct business; by downloading documentation from Our Site; by subscribing to newsletters or other communications; by corresponding with Us by phone, e-mail or otherwise providing contact details. In these cases, typically, the personal data You give Us may include name, business affiliation, business address, telephone number, and email address, and any personal details required to resolve any inquiries or complaints.`}</li>
    </ol>
    <p>{`This personal data is required to enter into a contract with You (such as in anticipation of a supply agreement, or to learn about Our Services) or to perform a contract with You (such as to provide Services), and failure to provide any information may result in Our inability to provide requested Services or products.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`2. Packet Loss and Latency`}</h3>
    <p>{`HostJane does not proactively monitor the packet loss or transmission latency of specific customers. HostJane does, however, proactively monitor the aggregate packet loss and transmission latency within its LAN and WAN. In the event that HostJane discovers (either from its own efforts or after being notified by You) that You are experiencing packet loss in excess of one percent (1%) ("Excess Packet Loss") between your instance and one hop from HostJane's border router(s) (first hop of egress providers router) and You notify HostJane via a support ticket (or HostJane has notified You of an event), HostJane will take all actions necessary to determine the source of the Excess Packet Loss/Latency.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
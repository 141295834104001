import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`20. Assignment; Successors`}</h2>
    <p>{`You may not assign or transfer this Agreement or any of Your rights or obligations hereunder,
without the prior written consent of HostJane. Any attempted assignment in violation of
this Agreement shall be null and void and of no force or effect whatsoever. HostJane may
assign Our rights and obligations under this Agreement, and may engage subcontractors or
agents in performing Our duties and exercising Our rights hereunder, without the consent of User.
This Agreement shall be binding upon and shall inure to the benefit of the parties hereto and
their respective successors and permitted assigns.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`2. Eligibility & Registration`}</h2>
    <p><strong parentName="p">{`2.1`}</strong>{` By registering for or using the Services, You represent and warrant that:`}</p>
    <ol>
      <li parentName="ol">{`You are eighteen (18) years of age or older. The Services are intended solely
for Users who are eighteen (18) years of age or older. Any registration,
use of or access to the Services, by anyone under eighteen (18) is unauthorized
and is a violation of this Agreement.`}</li>
      <li parentName="ol">{`If You use the Services on behalf of another party You agree that You are
authorized to bind such other party to this Agreement and to act on such other
party's behalf with respect to any actions You take in connection with the Services.`}</li>
    </ol>
    <p><strong parentName="p">{`2.2`}</strong>{` It is Your responsibility to provide accurate, current, and complete information
on the registration forms, including a valid email address. If there is ever an abuse
issue or We need to contact You, We will use the primary email address We have on file.
It is Your responsibility to ensure that the contact information for Your account,
including any financial information for payouts is accurate, correct and complete at
all times. HostJane is not responsible for any lapse in the Services, including without
limitation, any payout mistakes due to outdated or wrong contact information being
associated with Your account. `}</p>
    <p>{`If You need to verify or change Your contact and financial information, You need to use the HostJane Settings or `}<a parentName="p" {...{
        "href": "https://cloud.hostjane.com/hosting/login"
      }}>{`billing portal (for hosting orders)`}</a>{` to update Your contact
information. Providing false contact information of any kind may result in the termination of Your account. In certain purchases You may be required to verify a recent statement displaying Your name, billing address and the last 4 digits of the card used.
Failure to provide the requested information for review within 72 hours will result in cancellation of Your open orders.`}</p>
    <p><strong parentName="p">{`2.3`}</strong>{` You agree to be fully responsible for all use of Your account and for any actions that
take place through Your account. It is Your responsibility to maintain the confidentiality of
Your password and other information related to the security of Your account.`}</p>
    <p><strong parentName="p">{`2.4`}</strong>{` Any dedicated IP order in addition to those provided with a HostJane server
may be subject to IP justification. IP justification practices are subject to change to remain
in compliance with the policies of the American Registry for Internet Numbers (ARIN).
We reserve the right to deny any dedicated IP request based on insufficient justification
or current IP utilization.`}</p>
    <p><strong parentName="p">{`2.5`}</strong>{` The Service and any data You provide to HostJane is hosted in the United States (U.S.)
unless otherwise provided. If You access the Service from outside of the U.S., You are voluntarily
transferring information (potentially including personally-identifiable information) and content
to the U.S. and You hereby agree that HostJane’s collection, use, storage and sharing of Your
information and content is subject to the laws of the United States, and not necessarily of the
jurisdiction in which You are located.`}</p>
    <p><strong parentName="p">{`2.6`}</strong>{` The Services are subject to export control and economic sanctions laws and regulations
administered or enforced by the United States Department of Commerce, Department of Treasury’s
Office of Foreign Assets Control (“OFAC”), Department of State, and other United States authorities
(collectively, “U.S. Trade Laws”). You may not use the Services to export or reexport,
or permit the export or reexport, of software or technical data in violation of U.S. Trade Laws.
In addition, by using the Services, You represent and warrant that You are not (a) an individual,
organization or entity organized or located in a country or territory that is the target of OFAC
sanctions (including Cuba, Iran, Syria, Sudan, North Korea, or the Crimea region of Ukraine);
(b) designated as a Specially Designated National ("SDN") or Blocked Person by OFAC or otherwise owned,
controlled, or acting on behalf of such a person; (c) otherwise a prohibited party under
U.S. Trade Laws; or (d) engaged in nuclear, missile, chemical or biological weapons activities
to which U.S. persons may not contribute without a U.S. Government license. Unless otherwise
provided with explicit written permission, HostJane also does not register, and prohibits the
use of any of Our Services in connection with, any Country-Code Top Level Domain Name (“ccTLD”)
for any country or territory that is the target of OFAC sanctions. The obligations under this
section shall survive any termination or expiration of this Agreement or Your use of the Services.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
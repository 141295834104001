import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`14. Coupons; Discounts`}</h3>
    <p>{`Except in circumstances where discounts are provided in writing by HostJane on a case-by-case basis, coupon codes are generally reserved for first-time accounts unless otherwise specified. Any account found in violation of these policies will be reviewed by our Sales department and the appropriate charges will be added to the account. Coupon abuse will not be tolerated and may result in the suspension or termination of Your account. All coupons and discounts are only valid towards the initial purchase and do not affect the renewal or recurring price.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
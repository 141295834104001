import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`3. SELLERS`}</h2>
    <p>{`We need talented, skilled people to join HostJane and we welcome new sellers with an array of support resources to grow their freelance businesses. `}</p>
    <p>{`Sellers create fixed-price listings ("User Content") on HostJane which abides by the Acceptable Use Policy and General Terms of Use. Listings enable Buyers to benefit from the Seller's particular Skills through the Final Work that is delivered after purchasing Seller's Listing.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import { LegalBlock, LegalContent, LegalTldr } from "../../../../src/components/Legal/index.js";
import OneMainGdpr from "../../../../content/legal/parts/gdpr/1/gdpr-main.mdx";
import OneTldrGdpr from "../../../../content/legal/parts/gdpr/1/gdpr-tldr.mdx";
import TwoMainGdpr from "../../../../content/legal/parts/gdpr/2/gdpr-main.mdx";
import TwoTldrGdpr from "../../../../content/legal/parts/gdpr/2/gdpr-tldr.mdx";
import ThreeMainGdpr from "../../../../content/legal/parts/gdpr/3/gdpr-main.mdx";
import ThreeTldrGdpr from "../../../../content/legal/parts/gdpr/3/gdpr-tldr.mdx";
import FourMainGdpr from "../../../../content/legal/parts/gdpr/4/gdpr-main.mdx";
import FourTldrGdpr from "../../../../content/legal/parts/gdpr/4/gdpr-tldr.mdx";
import FiveMainGdpr from "../../../../content/legal/parts/gdpr/5/gdpr-main.mdx";
import FiveTldrGdpr from "../../../../content/legal/parts/gdpr/5/gdpr-tldr.mdx";
import SixMainGdpr from "../../../../content/legal/parts/gdpr/6/gdpr-main.mdx";
import SixTldrGdpr from "../../../../content/legal/parts/gdpr/6/gdpr-tldr.mdx";
import SevenMainGdpr from "../../../../content/legal/parts/gdpr/7/gdpr-main.mdx";
import SevenTldrGdpr from "../../../../content/legal/parts/gdpr/7/gdpr-tldr.mdx";
import EightMainGdpr from "../../../../content/legal/parts/gdpr/8/gdpr-main.mdx";
import EightTldrGdpr from "../../../../content/legal/parts/gdpr/8/gdpr-tldr.mdx";
import NineMainGdpr from "../../../../content/legal/parts/gdpr/9/gdpr-main.mdx";
import NineTldrGdpr from "../../../../content/legal/parts/gdpr/9/gdpr-tldr.mdx";
import TenMainGdpr from "../../../../content/legal/parts/gdpr/10/gdpr-main.mdx";
import TenTldrGdpr from "../../../../content/legal/parts/gdpr/10/gdpr-tldr.mdx";
import ElevenMainGdpr from "../../../../content/legal/parts/gdpr/11/gdpr-main.mdx";
import ElevenTldrGdpr from "../../../../content/legal/parts/gdpr/11/gdpr-tldr.mdx";
import TwelveMainGdpr from "../../../../content/legal/parts/gdpr/12/gdpr-main.mdx";
import TwelveTldrGdpr from "../../../../content/legal/parts/gdpr/12/gdpr-tldr.mdx";
import ThirteenMainGdpr from "../../../../content/legal/parts/gdpr/13/gdpr-main.mdx";
import ThirteenTldrGdpr from "../../../../content/legal/parts/gdpr/13/gdpr-tldr.mdx";
import FourteenMainGdpr from "../../../../content/legal/parts/gdpr/14/gdpr-main.mdx";
import FourteenTldrGdpr from "../../../../content/legal/parts/gdpr/14/gdpr-tldr.mdx";
import FifteenMainGdpr from "../../../../content/legal/parts/gdpr/15/gdpr-main.mdx";
import FifteenTldrGdpr from "../../../../content/legal/parts/gdpr/15/gdpr-tldr.mdx";
import * as React from 'react';
export default {
  LegalBlock,
  LegalContent,
  LegalTldr,
  OneMainGdpr,
  OneTldrGdpr,
  TwoMainGdpr,
  TwoTldrGdpr,
  ThreeMainGdpr,
  ThreeTldrGdpr,
  FourMainGdpr,
  FourTldrGdpr,
  FiveMainGdpr,
  FiveTldrGdpr,
  SixMainGdpr,
  SixTldrGdpr,
  SevenMainGdpr,
  SevenTldrGdpr,
  EightMainGdpr,
  EightTldrGdpr,
  NineMainGdpr,
  NineTldrGdpr,
  TenMainGdpr,
  TenTldrGdpr,
  ElevenMainGdpr,
  ElevenTldrGdpr,
  TwelveMainGdpr,
  TwelveTldrGdpr,
  ThirteenMainGdpr,
  ThirteenTldrGdpr,
  FourteenMainGdpr,
  FourteenTldrGdpr,
  FifteenMainGdpr,
  FifteenTldrGdpr,
  React
};
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Marketplace Rules`}</h2>
    <p>{`The following terms of Service ("Marketplace Rules") form part of HostJane’s `}<a parentName="p" {...{
        "href": "https://www.hostjane.com/legal/tos/"
      }}>{`General Terms`}</a>{` and govern Your ("User", "You" or "Your") use of the HostJane Marketplace for Skills found at HostJane.com (the "Service" or "Site"), including any content, payment processing, functionality and Services offered on or through the Site. HostJane’s Customer Support team is available 24/7, 365 days per year. If You have any questions regarding the Site or Terms of Service please contact Us at the bottom of this page. `}</p>
    <p>{`We strive to make HostJane a safe and secure environment for all Users. The Services operate with free registration, free setup, free use outside of Buyer Orders, and no Listing fees for Sellers. We deliberately do not change or segregate the Site experience based on Users acting as Buyers or Sellers. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
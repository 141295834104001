import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`3. Zero Tolerance on Spam Policy`}</h3>
    <p><strong parentName="p">{`3.1`}</strong>{` We take a zero tolerance stance against the sending of unsolicited e-mail, bulk emailing, and spam. "Safe lists," purchased lists, and selling of lists will be treated as spam. We may terminate the account of any User who sends out spam with or without notice. `}</p>
    <p><strong parentName="p">{`3.2`}</strong>{` Although federal law allows the dissemination of unsolicited bulk email under certain, tightly regulated conditions, we have elected to impose a stricter, total spam ban policy, for all affiliates and promoters of this website. This means that Members are prohibited from engaging in any bulk email promotions to disseminate their Studios or Listings or any other information about HostJane.com, regardless of how the recipient email addresses are acquired, generated or obtained. This also means that use of opt-in, double opt-in, or any form of email address recipient list is likewise prohibited, regardless of whether such activities are otherwise permitted by state or federal law.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
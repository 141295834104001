import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`18. Severability`}</h2>
    <p>{`If any provision or portion of any provision of this Agreement is found to be illegal,
invalid or unenforceable by a court of competent jurisdiction, the remaining provisions
or portions (unless otherwise specified) thereof shall remain in full force and effect.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
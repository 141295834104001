import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`9. HostJane-Initiated Termination`}</h3>
    <p><strong parentName="p">{`9.1`}</strong>{` HostJane may terminate Your access to the Services, in whole or in part, without notice in the event that: `}</p>
    <ol>
      <li parentName="ol">{`User fails to pay any subscription fees due and the next billing cycle has commenced; `}</li>
      <li parentName="ol">{`User violates this Hosting Terms, our General Terms or Acceptable Use Policy; `}</li>
      <li parentName="ol">{`User’s conduct may harm HostJane or others or cause HostJane or others to incur liability, as determined by HostJane in our sole discretion, or;`}</li>
      <li parentName="ol">{`As otherwise specified in this Terms. `}</li>
    </ol>
    <p><strong parentName="p">{`9.2`}</strong>{` In such event, HostJane shall not refund to You any fees paid in advance of such termination, and You shall be obligated to pay all fees and charges accrued prior to the effectiveness of such termination. Additionally, HostJane may charge You for all fees due for the Services for the remaining portion of the then current term.`}</p>
    <p><strong parentName="p">{`9.3`}</strong>{` UPON TERMINATION OF THE SERVICES FOR ANY REASON, USER CONTENT, USER WEBSITES, AND OTHER DATA WILL BE DELETED.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`21. Force Majeure`}</h2>
    <p>{`Neither party is liable for any default or delay in the performance of any of its obligations
under this Agreement (other than failure to make payments when due) if such default or delay
is caused, directly or indirectly, by forces beyond such party's reasonable control,
including, without limitation, fire, flood, acts of God, labor disputes, accidents,
acts of war or terrorism, interruptions of transportation or communications,
supply shortages or the failure of any third party to perform any commitment
relative to the production or delivery of any equipment or material required for such
party to perform its obligations hereunder.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`2.4 Warranties that Sellers make:`}</h3>
    <p>{`The Seller of a Service You buy warrants to You that:`}</p>
    <ol>
      <li parentName="ol">{`Their Service is of acceptable quality and fit for the purpose for which it is 'sold';`}</li>
      <li parentName="ol">{`They retain the necessary Skills to perform and supply You with the Service`}</li>
      <li parentName="ol">{`Their Service matches the description as given by the Seller on the Listing page at the time of the purchase;`}</li>
      <li parentName="ol">{`They will on-time deliver a Work Preview to You for approval within the agreed timeframe (that Seller sets for delivery during Listing setup), and within twenty-four (24) hours deliver the Final Work to You pending Your approval of the Work Preview;`}</li>
      <li parentName="ol">{`The un-watermarked or unrestricted Final Work released after Buyer has released payment and left public feedback will match the watermarked or restricted Work Preview that the Buyer approved;`}</li>
      <li parentName="ol">{`Seller will honor any express warranties given to You that are not contained in these terms;`}</li>
      <li parentName="ol">{`Seller holds all the right necessary to perform the Service as described in Listing either on basis of it’s their own intellectual property or on the terms of applicable License;`}</li>
      <li parentName="ol">{`Your use of the Service in accordance with the terms of the applicable License does not infringe the intellectual property rights of someone else;`}</li>
      <li parentName="ol">{`the Service and its description is not false, inaccurate, copyright infringing, misleading, fraudulent, or otherwise unlawful or defamatory;`}</li>
      <li parentName="ol">{`the Service and its description does not violate HostJane’s General Terms of Service or Acceptable Use Policy or any applicable law or regulation (including those governing export control, consumer protection, unfair competition, criminal law, pornography, anti-discrimination, trade practices or fair trading);`}</li>
      <li parentName="ol">{`the Seller’s delivered Work will not contain any viruses or other computer codes, files or programs which are designed to limit or destroy the functionality of other computer software or hardware; as described in our General Terms of Service neither HostJane nor Sellers will not be held accountable for damages to You that result from Site usage, User Content or files transferred.`}</li>
      <li parentName="ol">{`the Seller will support Your use of the delivered work to the extent described on the Listing page at the time of purchase`}</li>
      <li parentName="ol">{`the Seller will process Your data in accordance with applicable privacy law and data protection regulations`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`19. Waiver`}</h2>
    <p>{`No failure or delay by You or HostJane to exercise any right or remedy hereunder shall
operate as a waiver thereof, nor shall any single or partial exercise of any right or
remedy preclude any other or further exercise of any right or remedy. No express waiver
of, or assent to, any breach of or default in any term or condition of this Agreement by
any party hereto shall constitute a waiver of, or an assent to, any succeeding breach of
or default in the same or any other term or condition hereof.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Web Hosting Terms`}</h2>
    <p>{`This Web Hosting Terms of Service (the "Hosting Terms") is made between HostJane, Inc., a domestic U.S. corporation formed under the laws of the State of Wyoming (HostJane) and You (the “User”) an individual or legal entity who purchases a subscription to HostJane's Service during the set up process (the "Order") incorporating these Hosting Terms by reference. These Hosting Terms govern User’s operation of HostJane's Service and server products.`}</p>
    <h3>{`1. HostJane as Reseller or Licensor`}</h3>
    <p><strong parentName="p">{`1.1`}</strong>{` HostJane may act as a reseller or licensor of certain third party services, hardware, software and equipment used in connection with the Services ("Non-HostJane Products"). HostJane shall not be responsible for any changes in the Services that cause any Non-HostJane Products to become obsolete, require modification or alteration, or otherwise affect the performance of the Services. `}</p>
    <p><strong parentName="p">{`1.2`}</strong>{` Any malfunction or manufacturer's defects of Non-HostJane Products, either sold, licensed or provided by HostJane to You will not be deemed a breach of HostJane's obligations under this Agreement. Any rights or remedies You may have regarding the ownership, licensing, performance or compliance of any Non-HostJane Products are limited to those rights extended to You by the manufacturer of such Non-HostJane Product. `}</p>
    <p><strong parentName="p">{`1.3`}</strong>{` You are entitled to use any Non-HostJane Product supplied by HostJane only in connection with Your use of the Services as permitted under this Agreement. You shall make no attempt to copy, alter, reverse engineer, or tamper with such Non-HostJane Product or to use it other than in connection with the Services. You shall not resell, transfer, export or re-export any Non-HostJane Product, or any technical data derived therefrom, in violation of any applicable law, rules or regulations.`}</p>
    <p><strong parentName="p">{`1.4`}</strong>{` HostJane does not make any representations or warranties regarding, and is not liable for, the quality, availability, or timeliness of goods or services provided by a third party provider. You undertake all transactions with these third party providers at Your own risk. We do not warrant the accuracy or completeness of any information regarding third party providers. HostJane is not an agent, representative, trustee or fiduciary of You or the third party provider in any transaction.`}</p>
    <p><strong parentName="p">{`1.5`}</strong>{` Non-HostJane Products may be subject to the terms and conditions of the third party provider. Discounts, promotions and special third party offers may be subject to additional restrictions and limitations by the third party provider.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`14. DATA TRANSFER`}</h2>
    <p>{`In order for Us to provide the Services to You, Your personal information will be transferred to,
and stored at/processed in the United States. Your personal data may also be processed by staff
operating outside the European Economic Area (EEA), such as Australia, who work for Us or for
one of Our suppliers. We will take all steps reasonably necessary to ensure that Your
personal data is treated securely and in accordance with this policy.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`8. CHOICE/OPT-OUT OF MARKETING COMMUNICATIONS FROM US`}</h2>
    <h3>{`8.1 EMAIL`}</h3>
    <p>{`You always have the opportunity to opt out of Our marketing communications with You or change
Your preferences by following a link in the footer of all non-transactional email messages from
Us or by contacting Us at the bottom of this page. Some communications from Us are considered
transactional or Service communications (for example, important account notifications and
billing information). You agree to receive these transactional and Service communications as
a condition of the Services that We provide to You. You must cancel Your accounts for all Services,
if You do not wish to receive any transactional or Service communications from Us. To cancel
Your account, please follow the instructions found in the terms of Service for the applicable
Service. You may still receive marketing communications from Us even after You cancel Your account
unless You also opt-out of Our marketing communications, as described above.`}</p>
    <h3>{`8.2 Cookies`}</h3>
    <p>{`For information about how to manage and opt out from cookies, please visit Our Cookie Policy: `}<a parentName="p" {...{
        "href": "https://www.hostjane.com/legal/cookie-policy/"
      }}>{`https://www.hostjane.com/legal/cookie-policy/`}</a></p>
    <h3>{`8.3 INFORMATION FROM THIRD PARTIES`}</h3>
    <p>{`To manage the information We receive about You from a social networking site or other third party
(if applicable), You will need to follow the instructions from that party for updating Your
information and changing Your privacy settings, where available. The information We collect is
covered by this privacy notice and the information the third party collects is subject to such
third party’s privacy practices. Privacy choices You have made on any third party site will not
apply to Our use of the information We have collected directly through Our Services.`}</p>
    <h3>{`8.4 UNAUTHORIZED ACCOUNTS`}</h3>
    <p>{`If an account or profile was created without Your knowledge or authorization, please contact
customer support on which the account or profile is located to request removal of the account
or profile.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`7. TRACKING TECHNOLOGIES AND ONLINE ADVERTISING`}</h2>
    <p>{`We use cookies, web beacons, pixels, tags, scripts and other similar technologies in the course of
Our business. Information about the technologies We use, why We use them (for example, in connection
with online advertising), and how You can control them can be found in Our Cookie Notice available at:
`}<a parentName="p" {...{
        "href": "https://www.hostjane.com/legal/cookie-policy/"
      }}>{`https://www.hostjane.com/legal/cookie-policy/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
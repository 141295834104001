import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`3.1 Requirements for Selling`}</h3>
    <p><strong parentName="p">{`3.1.1`}</strong>{` To become a Seller, You need to create a valid Listing on the Site using text, audio, or video content that You warrant abides by Section 2.4 and does not conflict with any provision of HostJane's General Terms or Acceptable Use Policy. Listing creation is considered by HostJane to form part of the Pre-Delivery Stage. `}</p>
    <p><strong parentName="p">{`3.1.2`}</strong>{` Seller agrees to represent and abide by the list of warranties that Seller is required to make Buyer and HostJane, as given in Section 2.4 and 2.6 including the requirement to release Final Work to Buyer within twenty-four (24) hours of the Buyer completing order by releasing funds and leaving public feedback.`}</p>
    <p><strong parentName="p">{`3.1.3`}</strong>{` Limited Payment Collection Agent Agreement: Seller agrees to appoint HostJane as Seller's limited authorized payment collection agent solely for the purpose of accepting payments (via its Payment Services Provider, if applicable) from Buyer, and remitting those payments to Seller. `}</p>
    <p><strong parentName="p">{`3.1.4`}</strong>{` Seller agrees that payment from Buyer to HostJane is legally deemed to be the same as payment made directly to Seller. HostJane (via its Payment Services Provider, as applicable) is responsible for remitting the funds to the Seller in the outbound payment method described in these Marketplace Rules.`}</p>
    <p><strong parentName="p">{`3.1.5`}</strong>{` Buyer's payment obligation to Seller will be satisfied upon receipt of payment by HostJane (or its Payment Services Provider, as applicable)`}</p>
    <p><strong parentName="p">{`3.1.6`}</strong>{` Seller agrees not to accept payments using any method other than by through HostJane that leads to an Order, Custom Order or Tip. HostJane is not a directory or online portfolio service. Account suspension or closure may result if HostJane business is conducted outside HostJane.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
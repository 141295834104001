import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`10. Marketing emails`}</h3>
    <p><strong parentName="p">{`10.1`}</strong>{` Where lawful to do so, and subject to Your consent where required, we may communicate with You by e-mail to tell You about Our products and Services. If You wish to opt-out of receiving marketing communications, please use the 'unsubscribe' link provided in Our emails.`}</p>
    <p><strong parentName="p">{`10.2`}</strong>{` If You are a subscriber to Our Services, we may send You periodic announcements including the details of Our existing and new programs. You may opt out of these announcements by clicking the opt-out link at the bottom of these emails. If You opt out of these marketing emails, You may still receive system notices and other information that is specifically related to Your subscription account.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
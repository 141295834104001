import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`3. Other Limitations`}</h3>
    <p><strong parentName="p">{`3.1`}</strong>{` The uptime guarantee ONLY applies to network and instance availability during normal operation. The uptime guarantee does NOT apply to server-side software uptime. Any outage due to server software, operating systems, improper configurations, denial of service attack against your instance, instance suspension, instance paused/halted for any reason, or any other non-network or non-"host node" outage, for any reason and whether or not such outage is caused by Us due to upgrading, troubleshooting or performing any other tasks, is not subject to this uptime guarantee. The guarantee does NOT apply when a scheduled maintenance occurs with a minimum of twenty-four (24) hour notice or in the event a time critical patch/update must be applied as long as the outage/packetloss does not exceed ten (10) minutes.`}</p>
    <p><strong parentName="p">{`3.2`}</strong>{` Furthermore, none of the guarantees in this SLA may be combined. For example, a hardware outage that also disrupts network activity will not qualify You for two (2) separate credits. If for example, a hardware outage was the basis for the connectivity loss, the entire event will count as one single outage for the purpose of determining applicable credit.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
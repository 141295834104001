import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`5. Compliance with Applicable Law`}</h2>
    <p><strong parentName="p">{`5.1`}</strong>{` You agree to comply with all applicable laws, rules, and regulations,
including without limitation all local rules where You reside or Your organization
is located regarding User Content, User Websites, online activities, messaging other users,
and Your use of the Services. More specifically, but without limitation, You agree to comply
with all applicable laws regarding the transmission of technical data exported to or from
the United States or the country in which You reside. The Services are controlled and
operated by HostJane from Our offices within the United States (although We may share data
with third parties around the world to assist Us in providing the Services as further
described in Our Privacy Policy) and We make no representation that the Services are
appropriate or available for use in other locations. Those who access the Services from other
locations do so at their own initiative and risk, and are fully responsible for compliance
with all applicable laws in those locations. We do not offer the Services where prohibited by law.`}</p>
    <p><strong parentName="p">{`5.2`}</strong>{` For the purposes of European Directive 95/46/EC, the General Data Protection Regulation
2016/679) (“GDPR”) and any applicable national implementing laws in Your jurisdiction,
and with respect to Your subscribers’ or customers’ personal data, You acknowledge and
agree that You are the Controller (as that term is defined in the GDPR), and HostJane
is a Processor (as that term is defined in the GDPR) insofar as You may store personal
data through Your use of Our Services only as permitted and subject to the terms of this Agreement.
You also acknowledge and agree that You are responsible for complying with all obligations of a
data controller under applicable law (including the GDPR). To the extent the GDPR applies to You,
You represent and warrant that in using Our Services, You will clearly describe in writing how
You plan to use any personal data collected and You will ensure You have a legitimate legal basis
to transfer such personal data to Us and that You have the necessary permission to allow Us to
receive and process (e.g., store) such personal data on Your behalf. The additional data
processing terms set forth here shall apply where You are a Controller subject to the GDPR.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
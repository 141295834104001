import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`9. Children`}</h3>
    <p>{`The Site is not for use by anyone under the age of 18 years and HostJane does not knowingly collect, store, share or use the personal data of anyone under 18 years of age. If You are under the age of 18 years, please do not provide any personal data, even if prompted by the Site to do so. If You are under the age of 18 years and You have provided personal data, please ask Your parent(s) or guardian(s) to notify HostJane and HostJane will delete all such personal data.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
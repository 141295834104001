import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`2.15 Ownership of Final Work`}</h3>
    <p><strong parentName="p">{`2.15.1`}</strong>{` Unless clearly stated by the Seller on the Listing page or under applicable License agreements, Buyer is granted all intellectual property rights, title, and interest in the delivered Final Work upon release of payment to Seller and submission of public feedback.`}</p>
    <p><strong parentName="p">{`2.15.2`}</strong>{` Buyer agrees to choose the appropriate License (Personal, Limited or Unlimited) if Seller only sells Service on licensed basis. Any use of the Final Work outside the terms of the License is strictly prohibited and a violation of HostJane's General Terms and Marketplace Rules. This can lead to banning or account suspension.`}</p>
    <p><strong parentName="p">{`2.15.3`}</strong>{` If the Buyer changes intended or actual use of a licensed Final Work after order completion, Buyer agrees to contact Seller with 7 business days of the decision to pay the difference if applicable for a limited or unlimited License.`}</p>
    <p><strong parentName="p">{`2.15.4`}</strong>{` Under US Copyright law, the Final Work is generally deemed a 'work-for-hire' unless it's conditions fail the requirements of work-for-hire or the U.S. Copyright Act is not applicable, in which cases the Seller expressly agrees to assign to Buyer the copyright in the delivered Final Work.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
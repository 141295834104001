import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`4. HIPAA Disclaimer`}</h3>
    <p><strong parentName="p">{`4.1`}</strong>{` HostJane is not "HIPAA compliant": You are solely responsible for any applicable compliance with federal or state laws governing the privacy and security of personal data, including medical or other sensitive data. You acknowledge that the Services may not be appropriate for the storage or control of access to sensitive data, such as information about children or medical or health information. HostJane does not control or monitor the information or data You store on, or transmit through, the Services. `}</p>
    <p><strong parentName="p">{`4.2`}</strong>{` HostJane specifically disclaims any representation or warranty that the Services, as offered, comply with the federal Health Insurance Portability and Accountability Act ("HIPAA"). Customers requiring secure storage of "protected health information" as defined under HIPAA are expressly prohibited from using the Services for such purposes. Storing and permitting access to "protected health information" is a material violation of this Agreement, and grounds for immediate account termination. We do not sign "Business Associate Agreements" and You agree that HostJane is not a Business Associate or subcontractor or agent of yours pursuant to HIPAA. If You have questions about the security of Your data, You should contact HostJane at the bottom of this page.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
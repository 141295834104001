import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`3. Information From Other Sources`}</h3>
    <p>{`We may also obtain fraud & risk scoring data about You from third parties, for example MaxMind.`}</p>
    <h3>{`4. Cookies`}</h3>
    <p>{`Our Site uses cookies. More information about Our use of cookies can be found in Our cookie policy `}<a parentName="p" {...{
        "href": "http://www.HostJane.com/legal/cookie-policy/"
      }}>{`http://www.HostJane.com/legal/cookie-policy/`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`7. Disclosure of Personal Data`}</h3>
    <p>{`We may disclose personal data about Site visitors, users of Our Services, or Our suppliers or customers if, in Our sole discretion, we believe that it is reasonable to do so, including:`}</p>
    <ol>
      <li parentName="ol">{`To satisfy any laws, regulations, or governmental or legal requests for such data;`}</li>
      <li parentName="ol">{`To disclose personal data that is necessary to identify, contact, or bring legal action against someone who may be violating Our Acceptable Use Policy or other user policies;`}</li>
      <li parentName="ol">{`To protect ourselves, Our subscribers, and the general public. We specifically reserve the right to disclose any and all information (including personal data) to law enforcement in the event that a crime is committed, is suspected, or if we are compelled to do so by lawful criminal, civil, or administrative process, discovery requests, subpoenas, court orders, writs, or reasonable request of authorities or persons with the reasonable power to obtain such process.`}</li>
      <li parentName="ol">{`To cooperate with law enforcement authorities, private-party litigants, and others seeking information about Our end users to the extent required by applicable law. Examples of such cooperation include lawful criminal, civil, or administrative process, discovery requests, subpoenas, court orders, writs, or reasonable request of authorities or persons with the reasonable power to obtain such process.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`11. Disclaimer`}</h2>
    <p>{`HostJane shall not be responsible for any damages Your business may suffer.
HostJane makes no warranties of any kind, expressed or implied for the Services.
HostJane disclaims any warranty of merchantability or fitness for a particular purpose,
including loss of data resulting from delays, delivery failures, wrong deliveries,
and any and all Service interruptions caused by HostJane or Our employees.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
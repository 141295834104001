import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`B. Defamation Policy`}</h2>
    <p>{`Websites hosted on HostJane's servers in the U.S. are regulated by U.S. law. Accordingly, pursuant to Section 230(c) of the Communications Decency Act, HostJane's policy is not to remove allegedly defamatory material from websites hosted on Our servers unless the material has been found to be defamatory by a court, as evidenced by a court order. As a webhost, HostJane is not a publisher of User content and we are not in a position to investigate the veracity of individual defamation claims. We rely on the legal system and the courts to determine whether or not material is indeed defamatory. In any case in which a court order indicates that material is defamatory, libelous, or slanderous in nature, we will disable access to the material. Similarly, in any case in which a U.S. court has placed an injunction against specified content or material being made available, we will comply and remove or disable access to the material in question.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
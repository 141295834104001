import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`3.3 Random Acts of Kindness`}</h3>
    <p><strong parentName="p">{`3.3.1`}</strong>{` Sellers and Freelancers as defined in `}<strong parentName="p">{`1.6`}</strong>{` and `}<strong parentName="p">{`1.5a`}</strong>{` respectively can be recipients of Random Acts of Kindness as defined in `}<strong parentName="p">{`1.29`}</strong>{` as a charitable good deed to assist with living costs in the following categories: `}</p>
    <ul>
      <li parentName="ul">{`Donations towards rent and accomodation costs`}</li>
      <li parentName="ul">{`Donations towards gas (car petrol)`}</li>
      <li parentName="ul">{`Donations towards groceries like protein, dairy, fruit and veg, pantry staples`}</li>
      <li parentName="ul">{`Donations towards meal costs`}</li>
    </ul>
    <p><strong parentName="p">{`3.3.2`}</strong>{` HostJane will automatically add donated costs given as a Random Act of Kindness to Seller or Freelancer's "HostJane Balance" as described in `}<strong parentName="p">{`1.24`}</strong>{`.`}</p>
    <p><strong parentName="p">{`3.3.3`}</strong>{` Donations resulting from Random Acts of Kindness can be withdrawn after an expedited 48 hour waiting period and are paid to the Seller or Freelancer in full (for the charitable purpose of living cost asssitance) to honor the spirit of the Act of Kindness.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`A. Unacceptable Use`}</h2>
    <p>{`The following material is deemed unacceptable User Content either in Listings content for Marketplace Services, In-Site Messaging between Users, and/or as User Content residing on any HostJane Hosting Server products and Services:`}</p>
    <h3>{`1. Child Pornography Prohibited.`}</h3>
    <p>{`Content that is potentially harmful to minors, as determined in HostJane's sole discretion, is strictly forbidden, including but not limited to, child pornography or content perceived to be child pornography. Any website found to host child pornography or linking to child pornography will be suspended immediately without notice. POLICE AND FEDERAL LAW ENFORCEMENT AUTHORITIES WILL BE INVOLVED IMMEDIATELY.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`12. Backups & Data Loss`}</h2>
    <p>{`Your use of the Services is at Your sole risk. HostJane's backup Service runs once
a day and overwrites any of Our previous backups. Only one week of backups are kept at a time.
This Service is provided only as a courtesy and may be modified or terminated at any time
at HostJane's sole discretion. HostJane is not responsible for files and/or data
residing on Your account. You agree to take full responsibility for all files and data
transferred and to maintain all appropriate backup of files and data stored on HostJane's servers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
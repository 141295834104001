import { LegalBlock, LegalContent, LegalTldr } from "../../../../src/components/Legal/index.js";
import OneMainPrivacy from "../../../../content/legal/parts/privacy/1/privacy-main.mdx";
import OneTldrPrivacy from "../../../../content/legal/parts/privacy/1/privacy-tldr.mdx";
import TwoMainPrivacy from "../../../../content/legal/parts/privacy/2/privacy-main.mdx";
import TwoTldrPrivacy from "../../../../content/legal/parts/privacy/2/privacy-tldr.mdx";
import ThreeMainPrivacy from "../../../../content/legal/parts/privacy/3/privacy-main.mdx";
import ThreeTldrPrivacy from "../../../../content/legal/parts/privacy/3/privacy-tldr.mdx";
import FourMainPrivacy from "../../../../content/legal/parts/privacy/4/privacy-main.mdx";
import FourTldrPrivacy from "../../../../content/legal/parts/privacy/4/privacy-tldr.mdx";
import FiveMainPrivacy from "../../../../content/legal/parts/privacy/5/privacy-main.mdx";
import FiveTldrPrivacy from "../../../../content/legal/parts/privacy/5/privacy-tldr.mdx";
import SixMainPrivacy from "../../../../content/legal/parts/privacy/6/privacy-main.mdx";
import SixTldrPrivacy from "../../../../content/legal/parts/privacy/6/privacy-tldr.mdx";
import SevenMainPrivacy from "../../../../content/legal/parts/privacy/7/privacy-main.mdx";
import SevenTldrPrivacy from "../../../../content/legal/parts/privacy/7/privacy-tldr.mdx";
import EightMainPrivacy from "../../../../content/legal/parts/privacy/8/privacy-main.mdx";
import EightTldrPrivacy from "../../../../content/legal/parts/privacy/8/privacy-tldr.mdx";
import NineMainPrivacy from "../../../../content/legal/parts/privacy/9/privacy-main.mdx";
import NineTldrPrivacy from "../../../../content/legal/parts/privacy/9/privacy-tldr.mdx";
import TenMainPrivacy from "../../../../content/legal/parts/privacy/10/privacy-main.mdx";
import TenTldrPrivacy from "../../../../content/legal/parts/privacy/10/privacy-tldr.mdx";
import ElevenMainPrivacy from "../../../../content/legal/parts/privacy/11/privacy-main.mdx";
import ElevenTldrPrivacy from "../../../../content/legal/parts/privacy/11/privacy-tldr.mdx";
import TwelveMainPrivacy from "../../../../content/legal/parts/privacy/12/privacy-main.mdx";
import TwelveTldrPrivacy from "../../../../content/legal/parts/privacy/12/privacy-tldr.mdx";
import ThirteenMainPrivacy from "../../../../content/legal/parts/privacy/13/privacy-main.mdx";
import ThirteenTldrPrivacy from "../../../../content/legal/parts/privacy/13/privacy-tldr.mdx";
import FourteenMainPrivacy from "../../../../content/legal/parts/privacy/14/privacy-main.mdx";
import FourteenTldrPrivacy from "../../../../content/legal/parts/privacy/14/privacy-tldr.mdx";
import FifteenMainPrivacy from "../../../../content/legal/parts/privacy/15/privacy-main.mdx";
import FifteenTldrPrivacy from "../../../../content/legal/parts/privacy/15/privacy-tldr.mdx";
import SixteenMainPrivacy from "../../../../content/legal/parts/privacy/16/privacy-main.mdx";
import SixteenTldrPrivacy from "../../../../content/legal/parts/privacy/16/privacy-tldr.mdx";
import * as React from 'react';
export default {
  LegalBlock,
  LegalContent,
  LegalTldr,
  OneMainPrivacy,
  OneTldrPrivacy,
  TwoMainPrivacy,
  TwoTldrPrivacy,
  ThreeMainPrivacy,
  ThreeTldrPrivacy,
  FourMainPrivacy,
  FourTldrPrivacy,
  FiveMainPrivacy,
  FiveTldrPrivacy,
  SixMainPrivacy,
  SixTldrPrivacy,
  SevenMainPrivacy,
  SevenTldrPrivacy,
  EightMainPrivacy,
  EightTldrPrivacy,
  NineMainPrivacy,
  NineTldrPrivacy,
  TenMainPrivacy,
  TenTldrPrivacy,
  ElevenMainPrivacy,
  ElevenTldrPrivacy,
  TwelveMainPrivacy,
  TwelveTldrPrivacy,
  ThirteenMainPrivacy,
  ThirteenTldrPrivacy,
  FourteenMainPrivacy,
  FourteenTldrPrivacy,
  FifteenMainPrivacy,
  FifteenTldrPrivacy,
  SixteenMainPrivacy,
  SixteenTldrPrivacy,
  React
};
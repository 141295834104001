import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`4. User Content`}</h2>
    <p><strong parentName="p">{`4.1`}</strong>{` You may be able to upload, store, publish, display and distribute information,
text, photos, videos and other content on or through the Services (collectively, "User Content").
User Content includes any content posted by You or by users of any of Your websites
hosted through the Services ("User Websites"). You are solely responsible for any and all
User Content and any transactions or other activities conducted on or through User Websites.
By posting or distributing User Content on or through the Services, You represent and warrant
to HostJane that:`}</p>
    <ol>
      <li parentName="ol">{`You have all the necessary rights to post or distribute such User Content, and`}</li>
      <li parentName="ol">{`Your posting or distribution of such User Content does not infringe or violate
the rights of any third party.`}</li>
    </ol>
    <p><strong parentName="p">{`4.2`}</strong>{` Solely for purposes of providing the Services, You hereby grant to HostJane
a non-exclusive, royalty-free, worldwide right and license to: `}</p>
    <ol>
      <li parentName="ol">{`Use, reproduce, publicly perform, publicly display, modify, translate, excerpt
(in whole or in part), publish and distribute User Content; and `}</li>
      <li parentName="ol">{`Make archival or back-up copies of User Content and User Websites. Except for the
rights expressly granted herein, HostJane does not acquire any right, title or interest
in or to the User Content, all of which shall remain solely with You.`}</li>
    </ol>
    <p><strong parentName="p">{`4.3`}</strong>{` HostJane exercises no control over, and accepts no responsibility for,
User Content or the content of any information passing through HostJane's computers,
network hubs and points of presence or the Internet. HostJane does not monitor User Content.
However, You acknowledge and agree that HostJane may, but is not obligated to,
immediately take any corrective action in HostJane's sole discretion, including without
limitation removal of all or a portion of the User Content or User Websites, and suspend
or terminate any and all Services without refund if You violate the terms of this Agreement.
You hereby agree that HostJane shall have no liability due to any corrective action that
HostJane may take.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
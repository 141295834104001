import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`2.10 HostJane Initiated Cancellations in Delivery Stage`}</h3>
    <p>{`HostJane will cancel delivered orders, where Seller has delivered a Work Preview and prior to Buyer's agreement to release funds and provide public feedback, under the following conditions:`}</p>
    <p><strong parentName="p">{`2.10.1`}</strong>{` There has been a violation of HostJane's General Terms of Service or Acceptable Use Policy by either party`}</p>
    <p><strong parentName="p">{`2.10.2`}</strong>{` The Seller's Work Preview has been deemed by HostJane's customer service team to be unacceptable, unsatisfactory, or unduly inadequate in relation to the original Service offered by Seller in their Listing irrespective of whether Buyer has raised a dispute; this may result in Seller suspension and banning by HostJane.`}</p>
    <p><strong parentName="p">{`2.10.3`}</strong>{` If a delivered order is cancelled by HostJane at Work Preview stage, the full funds associated with that order less Payment Service Provider processing fees and Handling Fee will be returned to the Buyer's Balance or the Payment source used to pay for the order.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
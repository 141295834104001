import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`HostJane Cookie Policy`}</h2>
    <p>{`This Cookie Notice describes how and why HostJane, Inc. and Our subsidiaries (“We,” “Us”, "Our", or “HostJane”) use cookies, web beacons, pixels, tags, scripts and other similar technologies in the course of Our business, including through websites and apps that link to this Cookie Notice. It also explains Your rights to control Our use of these tracking technologies. For additional information about Our privacy practices, please review Our Privacy Notice: `}<a parentName="p" {...{
        "href": "https://www.hostjane.com/legal/privacy/"
      }}>{`https://www.hostjane.com/legal/privacy/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
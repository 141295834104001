import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`1. Ineligible Downtime`}</h3>
    <p>{`HostJane is not responsible for downtime related to the Services listed below and as such will not issues credits for the following:`}</p>
    <ol>
      <li parentName="ol">{`Internal services such as MySQL, Apache, PHP, etc.`}</li>
      <li parentName="ol">{`Scheduled downtime or planned maintenance`}</li>
      <li parentName="ol">{`Interruption of Service due to unpaid invoices, abuse notifications and violations of TOS`}</li>
      <li parentName="ol">{`ISP or local connection problems`}</li>
      <li parentName="ol">{`Customer-controlled downtime`}</li>
      <li parentName="ol">{`Acts of Force Majeure`}</li>
      <li parentName="ol">{`HostJane reserves the right to audit servers as needed and to perform administrative actions at the request of Our datacenter.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`5. Use of Personal Data`}</h3>
    <p><strong parentName="p">{`5.1`}</strong>{` The following is an overview of Our purposes for using Your personal data. Additional details on how we process Your personal data may be provided to You in a separate notice or contract. All processing and use of Your personal data is justified by a "condition" for processing. In the majority of cases, processing will be justified on the basis that:`}</p>
    <ol>
      <li parentName="ol">{`The processing is necessary to perform a contract with You (such as if You subscribe to Our Services) or take steps to enter into a contract at Your request (such as to fill an order), or to provide product information You have requested;`}</li>
      <li parentName="ol">{`the processing is necessary for Us to comply with a relevant legal obligation, such as keeping accounting records;`}</li>
      <li parentName="ol">{`The processing is in Our legitimate interests, which are not overridden by Your interests and fundamental rights. Our legitimate interests are to use subscriber, Site user, supplier and customer data to conduct and develop Our business activities with them and with others while limiting the use of their personal data to purposes that support the conduct and development of Our business; or`}</li>
      <li parentName="ol">{`You have consented to the processing.`}</li>
    </ol>
    <p><strong parentName="p">{`5.2`}</strong>{` We use the personal data we collect to:`}</p>
    <ol>
      <li parentName="ol">{`Provide You with Services You have subscribed for, or otherwise requested;`}</li>
      <li parentName="ol">{`provide You with documentation or communications which You have requested;`}</li>
      <li parentName="ol">{`Administer and manage performance of purchase or sales agreements with Our suppliers and customers;`}</li>
      <li parentName="ol">{`Provide after-sales support;`}</li>
      <li parentName="ol">{`Correspond with users to resolve their queries or complaints;`}</li>
      <li parentName="ol">{`Market Our Services to persons whose Services have expired or otherwise been cancelled;`}</li>
      <li parentName="ol">{`Engage You about events, promotions, the Site and HostJane's products and Services;`}</li>
      <li parentName="ol">{`Process, evaluate and complete certain transactions involving the Site, and more generally transactions involving HostJane's products and Services;`}</li>
      <li parentName="ol">{`Operate, evaluate, maintain, improve and develop the Site (including by monitoring and analysing trends, access to, and use of the Site for advertising and marketing);`}</li>
      <li parentName="ol">{`To increase Your (and other users') experience according to tracked interests, to analyze and target potential new markets, and for other marketing purposes.`}</li>
      <li parentName="ol">{`Protect and ensure safety of the Site, HostJane confidential and proprietary information, and HostJane employees;`}</li>
      <li parentName="ol">{`Manage, protect against and investigate fraud, risk exposure, claims and other liabilities, including but not limited to violation of Our contract terms or laws or regulations;`}</li>
      <li parentName="ol">{`Share Your personal data with third parties in connection with potential or actual sale of Our company or any of Our assets, or those of any affiliated company, in which case personal data held by Us about Our users may be one of the transferred assets;`}</li>
    </ol>
    <p>{`We also use non-personal data and aggregate information, such as that collected automatically, to customize Our marketing efforts or to customize the use of Our Site for an aggregate group of customers.`}</p>
    <p>{`HostJane will never sell or rent Your personal data to third parties.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
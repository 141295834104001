import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`13. Limited Warranty`}</h2>
    <p>{`THE SERVICES PROVIDED UNDER THIS AGREEMENT ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE BASIS."
EXCEPT AS EXPRESSLY PROVIDED IN THIS SECTION, HOSTJANE AND OUR AFFILIATES, EMPLOYEES, AGENTS,
SUPPLIERS AND LICENSORS DISCLAIM ALL WARRANTIES OF ANY KIND, INCLUDING BUT NOT LIMITED TO
IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT,
FOR THE SERVICES PROVIDED HEREUNDER. HOSTJANE AND OUR AFFILIATES, EMPLOYEES, AGENTS, SUPPLIERS
AND LICENSORS MAKE NO REPRESENTATIONS OR WARRANTIES (I) THAT THE SERVICES WILL BE UNINTERRUPTED,
ERROR FREE OR COMPLETELY SECURE; (II) AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
SERVICES; OR (III) AS TO THE ACCURACY, RELIABILITY OR CONTENT OF ANY INFORMATION PROVIDED THROUGH
THE SERVICES. HOSTJANE AND OUR AFFILIATES, EMPLOYEES, AGENTS, SUPPLIERS AND LICENSORS ARE NOT LIABLE,
AND EXPRESSLY DISCLAIMS ANY LIABILITY, FOR THE CONTENT OF ANY DATA TRANSFERRED EITHER TO OR FROM
USERS OR STORED BY USERS ON OR THROUGH THE SERVICES. THE TERMS OF THIS SECTION SHALL SURVIVE ANY
TERMINATION OF THIS AGREEMENT.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`8. Arbitration`}</h2>
    <p>{`By using the Services, You hereby submit to the exclusive jurisdiction of the
American Arbitration Association ("AAA") in connection with any dispute relating to,
concerning or arising out of this Agreement. The arbitration will be conducted before
a single arbitrator chosen by HostJane and will be held at the AAA location chosen by HostJane.
Payment of all filing, administrative and arbitrator fees will be governed by the AAA's rules,
unless otherwise stated in this paragraph. In the event You are able to demonstrate that the
costs of arbitration will be prohibitive as compared to the costs of litigation,
HostJane will pay as much of Your filing, administrative, and arbitrator fees in connection
with the arbitration as the arbitrator deems necessary to prevent the arbitration from being
cost-prohibitive. The arbitration before the AAA shall proceed solely on an individual basis
without the right for any claims to be arbitrated on a class action basis or on bases involving
claims brought in a purported representative capacity on behalf of others.
The Federal Arbitration Act, and not any state arbitration law, governs all arbitration under
this paragraph. All decisions rendered by the arbitrator will be binding and final.
The arbitrator's award is final and binding on all parties. The arbitrator's authority
to resolve and make written awards is limited to claims between You and HostJane alone.
Claims may not be joined or consolidated unless agreed to in writing by all parties.
No arbitration award or decision will have any preclusive effect as to issues or claims
in any dispute with anyone who is not a named party to the arbitration.
If You initiate litigation or any other proceeding against HostJane in violation of this paragraph,
You agree to pay HostJane's reasonable costs and attorneys' fees incurred in connection with Our
enforcement of this paragraph.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
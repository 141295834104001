import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`6. Important Information for Subscribers - Community Forum and Internal Messaging`}</h3>
    <p><strong parentName="p">{`6.1`}</strong>{` Any information (including personal data) provided in a user profile, member directory, or other related Service should be considered to be available to the public. We do not, cannot, and will not act to maintain the privacy of any information (including personal data) that You provide in any such forum or medium.`}</p>
    <p><strong parentName="p">{`6.2`}</strong>{` We provide an internal messaging system for Users to discuss Listings and Listing-related material. We take appropriate technical and organizational measures to protect the privacy and security of information Users transmit via this internal messaging system. However, no system is completely secure. Any information (including personal data) that You send via this internal messaging system is at Your own risk. You should use caution when sending any personal data over the internal messaging system, and sending of any passwords or financial information in messages (such as credit card or bank account information) is strictly prohibited.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
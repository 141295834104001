import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`17. Changes to the Agreement or the Services`}</h2>
    <p><strong parentName="p">{`17.1`}</strong>{` HostJane may modify, add, or delete portions of this Agreement at any time.
If We have made significant changes to this Agreement, We will post a notice on the
HostJane website for at least thirty (30) days after the changes are posted and will
indicate at the bottom of this Agreement the date of the last revision. Any revisions
to this Agreement will become effective when posted unless otherwise provided.
You agree to any modification to this Agreement by continuing to use the Services
after the effective date of any such modification. `}</p>
    <p><strong parentName="p">{`17.2`}</strong>{` HostJane reserves the right to modify, change, or discontinue any aspect of the
Services at any time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as InfoIcon } from '../../svgs/icons/info.svg';

export const LegalBlock = styled.section`
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: stretch;
`;

export const LegalContent = styled.article`
	flex: 0 0 100%;
	/** Force a top margin of 3rem on first child to make the line appear better */
	> *:first-child {
		margin-top: 3rem !important;
	}
	@media ${props => props.theme.breakpoints.desktopQuery} {
		flex-basis: calc(100% - 360px);
	}
`;

const LegalTldrWrap = styled.aside`
	flex: 0 0 100%;
	position: relative;
	padding-bottom: 3rem;
	&::after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		height: 1px;
		background-color: ${props => props.theme.neutrals.lightTwo};
		left: -${props => props.theme.gutter.small}px;
		right: -${props => props.theme.gutter.small}px;
		@media ${props => props.theme.breakpoints.tabletQuery} {
			left: -${props => props.theme.gutter.large}px;
			right: -${props => props.theme.gutter.large}px;
		}
		@media ${props => props.theme.breakpoints.desktopQuery} {
			left: -30px;
			right: auto;
			top: 3rem;
			bottom: 0;
			height: auto;
			width: 1px;
		}
	}
	@media ${props => props.theme.breakpoints.desktopQuery} {
		flex-basis: 300px;
		display: flex;
		align-items: flex-start;
		position: relative;
		padding-bottom: 0;
	}
`;

const LegalTldrSticky = styled.div`
	padding-top: 3rem;
	@media ${props => props.theme.breakpoints.desktopQuery} {
		position: sticky;
		top: 0;
	}
`;

const LegalTldrHeading = styled.div`
	color: ${props => props.theme.primary.light};
	font-weight: bold;
	font-size: 18px;
	display: flex;
	align-items: center;
	svg {
		display: inline-block;
		height: 18px;
		width: 18px;
		fill: currentColor;
		margin: 0 15px 0 0;
	}
`;

export function LegalTldr({ children }) {
	return (
		<LegalTldrWrap>
			<LegalTldrSticky>
				<LegalTldrHeading>
					<InfoIcon />
					<span>In plain English:</span>
				</LegalTldrHeading>
				{children}
			</LegalTldrSticky>
		</LegalTldrWrap>
	);
}

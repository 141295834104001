import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`1. WHAT ARE COOKIES?`}</h3>
    <p>{`Cookies are small data files placed on Your computer or other internet-enabled device that enable Our features and functionality. They allow Us to record information when You visit or interact with Our websites, products and Services (collectively, Our “Websites”). Other tracking technologies, such as web beacons and pixels work similarly to cookies, placing small data files on Your device that monitor Your Website activity.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
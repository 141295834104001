import { LegalBlock, LegalContent, LegalTldr } from "../../../../src/components/Legal/index.js";
import OneMainCookie from "../../../../content/legal/parts/cookie-policy/1/cookie-main.mdx";
import OneTldrCookie from "../../../../content/legal/parts/cookie-policy/1/cookie-tldr.mdx";
import TwoMainCookie from "../../../../content/legal/parts/cookie-policy/2/cookie-main.mdx";
import TwoTldrCookie from "../../../../content/legal/parts/cookie-policy/2/cookie-tldr.mdx";
import ThreeMainCookie from "../../../../content/legal/parts/cookie-policy/3/cookie-main.mdx";
import ThreeTldrCookie from "../../../../content/legal/parts/cookie-policy/3/cookie-tldr.mdx";
import FourMainCookie from "../../../../content/legal/parts/cookie-policy/4/cookie-main.mdx";
import FourTldrCookie from "../../../../content/legal/parts/cookie-policy/4/cookie-tldr.mdx";
import FiveMainCookie from "../../../../content/legal/parts/cookie-policy/5/cookie-main.mdx";
import FiveTldrCookie from "../../../../content/legal/parts/cookie-policy/5/cookie-tldr.mdx";
import SixMainCookie from "../../../../content/legal/parts/cookie-policy/6/cookie-main.mdx";
import SixTldrCookie from "../../../../content/legal/parts/cookie-policy/6/cookie-tldr.mdx";
import * as React from 'react';
export default {
  LegalBlock,
  LegalContent,
  LegalTldr,
  OneMainCookie,
  OneTldrCookie,
  TwoMainCookie,
  TwoTldrCookie,
  ThreeMainCookie,
  ThreeTldrCookie,
  FourMainCookie,
  FourTldrCookie,
  FiveMainCookie,
  FiveTldrCookie,
  SixMainCookie,
  SixTldrCookie,
  React
};
import { LegalBlock, LegalContent, LegalTldr } from "../../../../src/components/Legal/index.js";
import OneMainSla from "../../../../content/legal/parts/sla/1/sla-main.mdx";
import OneTldrSla from "../../../../content/legal/parts/sla/1/sla-tldr.mdx";
import TwoMainSla from "../../../../content/legal/parts/sla/2/sla-main.mdx";
import TwoTldrSla from "../../../../content/legal/parts/sla/2/sla-tldr.mdx";
import ThreeMainSla from "../../../../content/legal/parts/sla/3/sla-main.mdx";
import ThreeTldrSla from "../../../../content/legal/parts/sla/3/sla-tldr.mdx";
import FourMainSla from "../../../../content/legal/parts/sla/4/sla-main.mdx";
import FourTldrSla from "../../../../content/legal/parts/sla/4/sla-tldr.mdx";
import FiveMainSla from "../../../../content/legal/parts/sla/5/sla-main.mdx";
import FiveTldrSla from "../../../../content/legal/parts/sla/5/sla-tldr.mdx";
import * as React from 'react';
export default {
  LegalBlock,
  LegalContent,
  LegalTldr,
  OneMainSla,
  OneTldrSla,
  TwoMainSla,
  TwoTldrSla,
  ThreeMainSla,
  ThreeTldrSla,
  FourMainSla,
  FourTldrSla,
  FiveMainSla,
  FiveTldrSla,
  React
};
import { LegalBlock, LegalContent, LegalTldr } from "../../../../src/components/Legal/index.js";
import OneMainIHRA from "../../../../content/legal/parts/ihra-antisemitism/1/ihra-main.mdx";
import OneTldrIHRA from "../../../../content/legal/parts/ihra-antisemitism/1/ihra-tldr.mdx";
import TwoMainIHRA from "../../../../content/legal/parts/ihra-antisemitism/2/ihra-main.mdx";
import TwoTldrIHRA from "../../../../content/legal/parts/ihra-antisemitism/2/ihra-tldr.mdx";
import ThreeMainIHRA from "../../../../content/legal/parts/ihra-antisemitism/3/ihra-main.mdx";
import ThreeTldrIHRA from "../../../../content/legal/parts/ihra-antisemitism/3/ihra-tldr.mdx";
import * as React from 'react';
export default {
  LegalBlock,
  LegalContent,
  LegalTldr,
  OneMainIHRA,
  OneTldrIHRA,
  TwoMainIHRA,
  TwoTldrIHRA,
  ThreeMainIHRA,
  ThreeTldrIHRA,
  React
};
import { LegalBlock, LegalContent, LegalTldr } from "../../../../src/components/Legal/index.js";
import OneMainMP from "../../../../content/legal/parts/marketplace-terms/1/mp-main.mdx";
import OneTldrMP from "../../../../content/legal/parts/marketplace-terms/1/mp-tldr.mdx";
import TwoMainMP from "../../../../content/legal/parts/marketplace-terms/2/mp-main.mdx";
import TwoTldrMP from "../../../../content/legal/parts/marketplace-terms/2/mp-tldr.mdx";
import ThreeMainMP from "../../../../content/legal/parts/marketplace-terms/3/mp-main.mdx";
import ThreeTldrMP from "../../../../content/legal/parts/marketplace-terms/3/mp-tldr.mdx";
import TwentyTwoMainMP from "../../../../content/legal/parts/marketplace-terms/3guide/mp-main.mdx";
import TwentyTwoTldrMP from "../../../../content/legal/parts/marketplace-terms/3guide/mp-tldr.mdx";
import FourMainMP from "../../../../content/legal/parts/marketplace-terms/4/mp-main.mdx";
import FourTldrMP from "../../../../content/legal/parts/marketplace-terms/4/mp-tldr.mdx";
import FiveMainMP from "../../../../content/legal/parts/marketplace-terms/5/mp-main.mdx";
import FiveTldrMP from "../../../../content/legal/parts/marketplace-terms/5/mp-tldr.mdx";
import SixMainMP from "../../../../content/legal/parts/marketplace-terms/6/mp-main.mdx";
import SixTldrMP from "../../../../content/legal/parts/marketplace-terms/6/mp-tldr.mdx";
import SevenMainMP from "../../../../content/legal/parts/marketplace-terms/7/mp-main.mdx";
import SevenTldrMP from "../../../../content/legal/parts/marketplace-terms/7/mp-tldr.mdx";
import EightMainMP from "../../../../content/legal/parts/marketplace-terms/8/mp-main.mdx";
import EightTldrMP from "../../../../content/legal/parts/marketplace-terms/8/mp-tldr.mdx";
import NineMainMP from "../../../../content/legal/parts/marketplace-terms/9/mp-main.mdx";
import NineTldrMP from "../../../../content/legal/parts/marketplace-terms/9/mp-tldr.mdx";
import TenMainMP from "../../../../content/legal/parts/marketplace-terms/10/mp-main.mdx";
import TenTldrMP from "../../../../content/legal/parts/marketplace-terms/10/mp-tldr.mdx";
import ElevenMainMP from "../../../../content/legal/parts/marketplace-terms/11/mp-main.mdx";
import ElevenTldrMP from "../../../../content/legal/parts/marketplace-terms/11/mp-tldr.mdx";
import TwelveMainMP from "../../../../content/legal/parts/marketplace-terms/12/mp-main.mdx";
import TwelveTldrMP from "../../../../content/legal/parts/marketplace-terms/12/mp-tldr.mdx";
import ThirteenMainMP from "../../../../content/legal/parts/marketplace-terms/13/mp-main.mdx";
import ThirteenTldrMP from "../../../../content/legal/parts/marketplace-terms/13/mp-tldr.mdx";
import FourteenMainMP from "../../../../content/legal/parts/marketplace-terms/14/mp-main.mdx";
import FourteenTldrMP from "../../../../content/legal/parts/marketplace-terms/14/mp-tldr.mdx";
import FifteenMainMP from "../../../../content/legal/parts/marketplace-terms/15/mp-main.mdx";
import FifteenTldrMP from "../../../../content/legal/parts/marketplace-terms/15/mp-tldr.mdx";
import SixteenMainMP from "../../../../content/legal/parts/marketplace-terms/16/mp-main.mdx";
import SixteenTldrMP from "../../../../content/legal/parts/marketplace-terms/16/mp-tldr.mdx";
import SeventeenMainMP from "../../../../content/legal/parts/marketplace-terms/17/mp-main.mdx";
import SeventeenTldrMP from "../../../../content/legal/parts/marketplace-terms/17/mp-tldr.mdx";
import EighteenMainMP from "../../../../content/legal/parts/marketplace-terms/18/mp-main.mdx";
import EighteenTldrMP from "../../../../content/legal/parts/marketplace-terms/18/mp-tldr.mdx";
import NineteenMainMP from "../../../../content/legal/parts/marketplace-terms/19/mp-main.mdx";
import NineteenTldrMP from "../../../../content/legal/parts/marketplace-terms/19/mp-tldr.mdx";
import TwentyMainMP from "../../../../content/legal/parts/marketplace-terms/20/mp-main.mdx";
import TwentyTldrMP from "../../../../content/legal/parts/marketplace-terms/20/mp-tldr.mdx";
import TwentyOneMainMP from "../../../../content/legal/parts/marketplace-terms/21/mp-main.mdx";
import TwentyOneTldrMP from "../../../../content/legal/parts/marketplace-terms/21/mp-tldr.mdx";
import TwentyThreeMainMP from "../../../../content/legal/parts/marketplace-terms/23/mp-main.mdx";
import TwentyThreeTldrMP from "../../../../content/legal/parts/marketplace-terms/23/mp-tldr.mdx";
import * as React from 'react';
export default {
  LegalBlock,
  LegalContent,
  LegalTldr,
  OneMainMP,
  OneTldrMP,
  TwoMainMP,
  TwoTldrMP,
  ThreeMainMP,
  ThreeTldrMP,
  TwentyTwoMainMP,
  TwentyTwoTldrMP,
  FourMainMP,
  FourTldrMP,
  FiveMainMP,
  FiveTldrMP,
  SixMainMP,
  SixTldrMP,
  SevenMainMP,
  SevenTldrMP,
  EightMainMP,
  EightTldrMP,
  NineMainMP,
  NineTldrMP,
  TenMainMP,
  TenTldrMP,
  ElevenMainMP,
  ElevenTldrMP,
  TwelveMainMP,
  TwelveTldrMP,
  ThirteenMainMP,
  ThirteenTldrMP,
  FourteenMainMP,
  FourteenTldrMP,
  FifteenMainMP,
  FifteenTldrMP,
  SixteenMainMP,
  SixteenTldrMP,
  SeventeenMainMP,
  SeventeenTldrMP,
  EighteenMainMP,
  EighteenTldrMP,
  NineteenMainMP,
  NineteenTldrMP,
  TwentyMainMP,
  TwentyTldrMP,
  TwentyOneMainMP,
  TwentyOneTldrMP,
  TwentyThreeMainMP,
  TwentyThreeTldrMP,
  React
};
import { LegalBlock, LegalContent, LegalTldr } from "../../../../src/components/Legal/index.js";
import OneMainHosting from "../../../../content/legal/parts/hosting-terms/1/hosting-main.mdx";
import OneTldrHosting from "../../../../content/legal/parts/hosting-terms/1/hosting-tldr.mdx";
import TwoMainHosting from "../../../../content/legal/parts/hosting-terms/2/hosting-main.mdx";
import TwoTldrHosting from "../../../../content/legal/parts/hosting-terms/2/hosting-tldr.mdx";
import ThreeMainHosting from "../../../../content/legal/parts/hosting-terms/3/hosting-main.mdx";
import ThreeTldrHosting from "../../../../content/legal/parts/hosting-terms/3/hosting-tldr.mdx";
import FourMainHosting from "../../../../content/legal/parts/hosting-terms/4/hosting-main.mdx";
import FourTldrHosting from "../../../../content/legal/parts/hosting-terms/4/hosting-tldr.mdx";
import FiveMainHosting from "../../../../content/legal/parts/hosting-terms/5/hosting-main.mdx";
import FiveTldrHosting from "../../../../content/legal/parts/hosting-terms/5/hosting-tldr.mdx";
import SixMainHosting from "../../../../content/legal/parts/hosting-terms/6/hosting-main.mdx";
import SixTldrHosting from "../../../../content/legal/parts/hosting-terms/6/hosting-tldr.mdx";
import SevenMainHosting from "../../../../content/legal/parts/hosting-terms/7/hosting-main.mdx";
import SevenTldrHosting from "../../../../content/legal/parts/hosting-terms/7/hosting-tldr.mdx";
import EightMainHosting from "../../../../content/legal/parts/hosting-terms/8/hosting-main.mdx";
import EightTldrHosting from "../../../../content/legal/parts/hosting-terms/8/hosting-tldr.mdx";
import NineMainHosting from "../../../../content/legal/parts/hosting-terms/9/hosting-main.mdx";
import NineTldrHosting from "../../../../content/legal/parts/hosting-terms/9/hosting-tldr.mdx";
import TenMainHosting from "../../../../content/legal/parts/hosting-terms/10/hosting-main.mdx";
import TenTldrHosting from "../../../../content/legal/parts/hosting-terms/10/hosting-tldr.mdx";
import ElevenMainHosting from "../../../../content/legal/parts/hosting-terms/11/hosting-main.mdx";
import ElevenTldrHosting from "../../../../content/legal/parts/hosting-terms/11/hosting-tldr.mdx";
import TwelveMainHosting from "../../../../content/legal/parts/hosting-terms/12/hosting-main.mdx";
import TwelveTldrHosting from "../../../../content/legal/parts/hosting-terms/12/hosting-tldr.mdx";
import ThirteenMainHosting from "../../../../content/legal/parts/hosting-terms/13/hosting-main.mdx";
import ThirteenTldrHosting from "../../../../content/legal/parts/hosting-terms/13/hosting-tldr.mdx";
import FourteenMainHosting from "../../../../content/legal/parts/hosting-terms/14/hosting-main.mdx";
import FourteenTldrHosting from "../../../../content/legal/parts/hosting-terms/14/hosting-tldr.mdx";
import * as React from 'react';
export default {
  LegalBlock,
  LegalContent,
  LegalTldr,
  OneMainHosting,
  OneTldrHosting,
  TwoMainHosting,
  TwoTldrHosting,
  ThreeMainHosting,
  ThreeTldrHosting,
  FourMainHosting,
  FourTldrHosting,
  FiveMainHosting,
  FiveTldrHosting,
  SixMainHosting,
  SixTldrHosting,
  SevenMainHosting,
  SevenTldrHosting,
  EightMainHosting,
  EightTldrHosting,
  NineMainHosting,
  NineTldrHosting,
  TenMainHosting,
  TenTldrHosting,
  ElevenMainHosting,
  ElevenTldrHosting,
  TwelveMainHosting,
  TwelveTldrHosting,
  ThirteenMainHosting,
  ThirteenTldrHosting,
  FourteenMainHosting,
  FourteenTldrHosting,
  React
};
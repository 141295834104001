import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`11.	Security`}</h3>
    <p>{`We take measures, including data encryption, to protect the transmission of all sensitive end-user information. We make reasonable efforts to ensure the integrity and security of Our network and systems. Nevertheless, we cannot guarantee that Our security measures will prevent third-party 'hackers' from illegally obtaining this information. We take all reasonable measures to prevent such breaches of security, but given the resourcefulness of cyber-criminals we are unable to guarantee that Our security is 100% breach-proof. You assume the risk of such breaches to the extent that they occur despite Our reasonable security measures.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
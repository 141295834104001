import { LegalBlock, LegalContent, LegalTldr } from "../../../../src/components/Legal/index.js";
import OneMain from "../../../../content/legal/parts/tos/1/main.mdx";
import OneTldr from "../../../../content/legal/parts/tos/1/tldr.mdx";
import TwoMain from "../../../../content/legal/parts/tos/2/main.mdx";
import TwoTldr from "../../../../content/legal/parts/tos/2/tldr.mdx";
import ThreeMain from "../../../../content/legal/parts/tos/3/main.mdx";
import ThreeTldr from "../../../../content/legal/parts/tos/3/tldr.mdx";
import FourMain from "../../../../content/legal/parts/tos/4/main.mdx";
import FourTldr from "../../../../content/legal/parts/tos/4/tldr.mdx";
import FiveMain from "../../../../content/legal/parts/tos/5/main.mdx";
import FiveTldr from "../../../../content/legal/parts/tos/5/tldr.mdx";
import SixMain from "../../../../content/legal/parts/tos/6/main.mdx";
import SixTldr from "../../../../content/legal/parts/tos/6/tldr.mdx";
import SevenMain from "../../../../content/legal/parts/tos/7/main.mdx";
import SevenTldr from "../../../../content/legal/parts/tos/7/tldr.mdx";
import EightMain from "../../../../content/legal/parts/tos/8/main.mdx";
import EightTldr from "../../../../content/legal/parts/tos/8/tldr.mdx";
import NineMain from "../../../../content/legal/parts/tos/9/main.mdx";
import NineTldr from "../../../../content/legal/parts/tos/9/tldr.mdx";
import TenMain from "../../../../content/legal/parts/tos/10/main.mdx";
import TenTldr from "../../../../content/legal/parts/tos/10/tldr.mdx";
import ElevenMain from "../../../../content/legal/parts/tos/11/main.mdx";
import ElevenTldr from "../../../../content/legal/parts/tos/11/tldr.mdx";
import TwelveMain from "../../../../content/legal/parts/tos/12/main.mdx";
import TwelveTldr from "../../../../content/legal/parts/tos/12/tldr.mdx";
import ThirteenMain from "../../../../content/legal/parts/tos/13/main.mdx";
import ThirteenTldr from "../../../../content/legal/parts/tos/13/tldr.mdx";
import FourteenMain from "../../../../content/legal/parts/tos/14/main.mdx";
import FourteenTldr from "../../../../content/legal/parts/tos/14/tldr.mdx";
import FifteenMain from "../../../../content/legal/parts/tos/15/main.mdx";
import FifteenTldr from "../../../../content/legal/parts/tos/15/tldr.mdx";
import SixteenMain from "../../../../content/legal/parts/tos/16/main.mdx";
import SixteenTldr from "../../../../content/legal/parts/tos/16/tldr.mdx";
import SeventeenMain from "../../../../content/legal/parts/tos/17/main.mdx";
import SeventeenTldr from "../../../../content/legal/parts/tos/17/tldr.mdx";
import EighteenMain from "../../../../content/legal/parts/tos/18/main.mdx";
import EighteenTldr from "../../../../content/legal/parts/tos/18/tldr.mdx";
import NineteenMain from "../../../../content/legal/parts/tos/19/main.mdx";
import NineteenTldr from "../../../../content/legal/parts/tos/19/tldr.mdx";
import TwentyMain from "../../../../content/legal/parts/tos/20/main.mdx";
import TwentyTldr from "../../../../content/legal/parts/tos/20/tldr.mdx";
import TwentyOneMain from "../../../../content/legal/parts/tos/21/main.mdx";
import TwentyOneTldr from "../../../../content/legal/parts/tos/21/tldr.mdx";
import TwentyTwoMain from "../../../../content/legal/parts/tos/22/main.mdx";
import TwentyTwoTldr from "../../../../content/legal/parts/tos/22/tldr.mdx";
import * as React from 'react';
export default {
  LegalBlock,
  LegalContent,
  LegalTldr,
  OneMain,
  OneTldr,
  TwoMain,
  TwoTldr,
  ThreeMain,
  ThreeTldr,
  FourMain,
  FourTldr,
  FiveMain,
  FiveTldr,
  SixMain,
  SixTldr,
  SevenMain,
  SevenTldr,
  EightMain,
  EightTldr,
  NineMain,
  NineTldr,
  TenMain,
  TenTldr,
  ElevenMain,
  ElevenTldr,
  TwelveMain,
  TwelveTldr,
  ThirteenMain,
  ThirteenTldr,
  FourteenMain,
  FourteenTldr,
  FifteenMain,
  FifteenTldr,
  SixteenMain,
  SixteenTldr,
  SeventeenMain,
  SeventeenTldr,
  EighteenMain,
  EighteenTldr,
  NineteenMain,
  NineteenTldr,
  TwentyMain,
  TwentyTldr,
  TwentyOneMain,
  TwentyOneTldr,
  TwentyTwoMain,
  TwentyTwoTldr,
  React
};
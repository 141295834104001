import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`15. Changes to this Notice`}</h3>
    <p>{`We reserve the right to revise, amend, or modify this Notice and Our other policies and agreements at any time and in any manner. You should periodically check for any modifications of this Notice by re-visiting this web page and using the 'refresh' button on Your browser. You should note the date of last revision to this Notice, which appears at the top of this Notice. If the 'last modified' date remains unchanged after You have clicked the 'refresh' button on Your browser, You may presume that no changes have been made since the last reading of the Notice. A changed 'last modified' dates indicates that this Notice has been updated or edited, and the updated or edited version supersedes any prior versions immediately upon posting.`}</p>
    <h3>{`16. Contact Information`}</h3>
    <p>{`If You have any questions in relation to this Notice or You wish to exercise any of Your rights, please contact Us at the bottom of this page.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`2.14 Ownership of Work Previews`}</h3>
    <p><strong parentName="p">{`2.14.1`}</strong>{` During Delivery stage, `}<strong parentName="p">{`Work Previews`}</strong>{` are expected to be delivered from Seller to Buyer for inspection with certain restrictions based on the type and category of Work performed:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Software / Web / Mobile Development Works:`}</strong>{` Preview should be view-only access to a staging server owned by Seller where Buyer can inspect requested Work without download access;`}</li>
      <li parentName="ol"><strong parentName="li">{`Audio / Music Works:`}</strong>{` Preview should be in a restricted, abridged format or produced with an audible background copyright notice that repeats every 10 seconds; rendering previewed track unusable;`}</li>
      <li parentName="ol"><strong parentName="li">{`Image-based Works:`}</strong>{` Preview should contain a permanent non-removable watermark that renders Work unusable in previewed format;`}</li>
      <li parentName="ol"><strong parentName="li">{`Text-based Works:`}</strong>{` Preview should be in a restricted, partially redacted or abridged format;`}</li>
      <li parentName="ol"><strong parentName="li">{`Online Bookings:`}</strong>{` Preview should be notice of confirmed appointment at beginning of the booking (for example during Skype, Zoom video or FaceTime chat); Buyer should accept and release funds and leave feedback while still connected in the booking or within 1 hour of the booking ending;`}</li>
    </ol>
    <p><strong parentName="p">{`2.14.2`}</strong>{` The Seller retains all intellectual property rights of the delivered Work Preview until the Buyer completes the order, unless otherwise specified by the Seller under applicable License agreements or represented in the Listing content.`}</p>
    <p><strong parentName="p">{`2.14.3`}</strong>{` HostJane encourages (but does not enforce) Seller to initially deliver an unusable preview of the Work and not the ready-to-use Final Work until Buyer has approved and left public feedback.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import React from 'react';
import styled from 'styled-components';

const IconSpan = styled.span`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 1em;
	height: 1em;
	svg {
		fill: currentColor;
		height: 1em;
		width: 1em;
	}
`;

export default function GenericIcon({
	children,
	title = null,
	className = null,
}) {
	return (
		<IconSpan role="presentation" title={title} className={className}>
			{React.cloneElement(children, { 'aria-label': title })}
		</IconSpan>
	);
}
